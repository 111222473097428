<template>
  <div class="mx-8 my-8">

    <h1 class="text-3xl mb-5">Usuários</h1>

    <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 md:col-span-2 mb-1 mt-1">
          <router-link :to="`/${route}/form`" type="button" class="transition duration-200 bg-green-500 hover:bg-green-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2  rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center mb-2">
           <span class="inline-block mr-2">Novo usuário</span>
          </router-link>
        </div>
        <div class="col-span-12 md:col-span-2 mb-1 mt-1">
          <button @click="$modal.show('deletarEmail')" type="button" class="transition duration-200 bg-red-500 hover:bg-red-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2  rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center mb-2">
           <span class="inline-block mr-2">Deletar email</span>
          </button>
        </div>
    </div>

    <div class="grid grid-cols-12 gap-6 mb-3">
      <div class="col-span-12 md:col-span-4 mb-1 mt-1">
          <label for="busca" class="block text-sm font-medium">Buscar Nome / Email</label>
          <input v-model="busca" type="text" name="busca" id="busca" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
      </div>
      <div class="col-span-12 md:col-span-2 mb-1 mt-1">
        <label for="removidos" class="block text-sm font-medium mt-6">
            <input type="checkbox" v-model="desativados" class="rounded-sm" id="removidos">
            <span class="ml-2">
                Visualizar excluídos
            </span>
        </label>
      </div>
      
      <div class="col-span-12 md:col-span-2 mb-1 mt-2">
        <label class="block text-sm font-medium">Buscar por filtro</label>
        <button @click="updateFiltro" class="bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2  rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
      </div>
      <div class="col-span-12 md:col-span-2 mb-1 mt-2">
        <label class="block text-sm font-medium">Extrair</label>
        <a target="_blank" :href="`${url_api}/v1/${route}/exportar/users?token=${$store.state.token}`" type="button" class="bg-blue-800 hover:bg-blue-900 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2  rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Download </a>
      </div>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <h4 class="font-bold text-base mb-2">{{ total}} usuários cadastrados </h4>
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Cookie Banner?
                  </th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Nome
                  </th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Email
                  </th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Último acesso
                  </th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Telefone
                  </th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Cadastro
                  </th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Plano
                  </th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    QTD Projetos
                  </th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Indicações
                  </th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Opções
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="item in list" :key="item._id">
                  <td class="px-2 py-3">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        <svg v-if="item.cookieBanner" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M164.49,163.51a12,12,0,1,1-17,0A12,12,0,0,1,164.49,163.51Zm-81-8a12,12,0,1,0,17,0A12,12,0,0,0,83.51,155.51Zm9-39a12,12,0,1,0-17,0A12,12,0,0,0,92.49,116.49Zm48-1a12,12,0,1,0,0,17A12,12,0,0,0,140.49,115.51ZM232,128A104,104,0,1,1,128,24a8,8,0,0,1,8,8,40,40,0,0,0,40,40,8,8,0,0,1,8,8,40,40,0,0,0,40,40A8,8,0,0,1,232,128Zm-16.31,7.39A56.13,56.13,0,0,1,168.5,87.5a56.13,56.13,0,0,1-47.89-47.19,88,88,0,1,0,95.08,95.08Z"></path></svg>
                        {{ item.assinatura.stripe ? item.assinatura.stripe.plano : '' }}
                      </div>
                    </div>
                  </td>
                  <td class="px-2 py-3">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.name ? item.name : ''}}
                      </div>
                    </div>
                  </td>

                  <td class="px-2 py-3">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.email ? item.email : ''}}
                      </div>
                    </div>
                  </td>
                  <td class="px-2 py-3">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.ultimoLogin ? formatData(item.ultimoLogin) : ''}}
                      </div>
                    </div>
                  </td>

                  <td class="px-2 py-3">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.phone ? item.phone : ''}}
                      </div>
                    </div>
                  </td>

                  <td class="px-2 py-3">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.data | moment("DD/MM/YYYY")}}
                      </div>
                    </div>
                  </td>

                  <td class="px-2 py-3">
                    <div class="flex items-center">
                      <div v-if="!item.fluxoAssinaturaManual && item.assinatura.subscriptions" class="text-sm text-gray-500" :class="{'text-red-500': getStatus(item.assinatura.status) === 'Plano cancelado' || getStatus(item.assinatura.status) === 'Falha pagamento', 'text-blue-500': getStatus(item.assinatura.status) === 'Aguardando pagamento' || getStatus(item.assinatura.status) === 'Processando Assinatura' || getStatus(item.assinatura.status) === 'Processando Pagamento', 'text-green-500': getStatus(item.assinatura.status) === 'Plano pago'}">
                        {{getStatus(item.assinatura.status)}}
                        <span v-if="item.assinatura.pago">Validade: {{item.assinatura.validade | moment("DD/MM/YYYY")}}</span>
                      </div>
                      <div v-if="!item.fluxoAssinaturaManual && !item.assinatura.subscriptions" class="text-sm text-yellow-500">
                        <span :class="$moment(item.data).add(15,'days').endOf('days').diff($moment().endOf('days'), 'days') < 3 ? 'text-red-600' : ''">Dias de teste disponível: {{ $moment(item.data).add(15,'days').endOf('days').diff($moment().endOf('days'), 'days') > 0 ? $moment(item.data).add(15,'days').endOf('days').diff($moment().endOf('days'), 'days') : 'Finalizado' }}</span>
                      </div>
                      <div v-if="item.fluxoAssinaturaManual" class="text-sm text-gray-700" >
                        Fluxo Manual
                      </div>
                    </div>
                  </td>

                  <td class="px-2 py-3">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{ item.qtdEmpresa ? item.qtdEmpresa :  '' }}<br/>
                        <button v-if="!item.transferido" @click="alterarLimitacao(item)" class="text-white text-xs hover:bg-blue-500 bg-blue-700 rounded mt-1 py-1 px-3">
                          Alterar
                        </button>
                      </div>
                    </div>
                  </td>

                  <td class="px-2 py-3">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{ item.indicados && item.indicados.length ? item.indicados.length :  0 }}
                        <router-link 
                          v-if="item.indicados && item.indicados.length > 0"
                          :to="`/${route}/indicacoes/${item._id}`" 
                          class="text-blue-500 rounded py-1 px-2">
                          Visualizar
                        </router-link>
                      </div>
                    </div>
                  </td>
                  <td v-if="!desativados" class="px-2 py-3 text-sm font-medium">

                    <router-link 
                    :to="`/${route}/form/${item._id}`" 
                    class="text-blue-500 rounded py-1 px-2 my-1">
                    Editar
                    </router-link>

                    <button @click="gerarLogin(item._id)" class="text-white hover:bg-green-300 bg-green-500 rounded py-1 px-3 mx-1 my-1">
                      Login
                    </button>

                    <button @click="openModalPlano(item)" class="text-white hover:bg-blue-500 bg-blue-700 rounded py-1 px-3 mx-1 my-1">
                      Plano
                    </button>

                    <button @click="remove(item._id)" class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-3 mx-1 my-1">
                      Excluir
                    </button>

                  </td>
                  <td v-else class="px-2 py-3 whitespace-nowrap text-sm font-medium">
                    <button @click="reativar(item._id)" class="text-white hover:bg-yellow-600 bg-yellow-500 rounded py-1 px-3 mx-1 my-1">
                      Reativar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
        </div>
      </div>
    </div>
    <modal name="deletarEmail" :adaptive="true" :height="'auto'">
      <div class="px-5 py-5">
        <h2 class="text-xl font-medium mb-1"> Deletar vinculo email</h2>
        <p class="text-base text-red-600 mb-2">Usuários / Profissionais vinculado a este email serão excluídos.</p>
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12">
            <label for="email" class="block text-sm font-medium">Email</label>
            <input v-model="emailDeletar" type="text" name="email" id="email" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-12">
            <button @click="deletarEmail()" class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-3 mx-1">
              Deletar email
            </button>
          </div>
        </div>
      </div>
    </modal>
    <modal name="plano" :adaptive="true" :height="'auto'">
      <div v-if="assinatura && user" class="px-5 py-5">
        <h3 class="text-gray-800 font-r font-medium text-2xl mb-2">Plano: {{ assinatura.plano_nome }} </h3>
        <hr class="my-3" />
        <div v-if="!user.fluxoAssinaturaManual">
          <h3 class="text-gray-800 font-r font-medium text-xl mb-2">Pago: {{ assinatura.pago ? 'Sim': 'Não' }} <span v-if="assinatura.pago && assinatura.pagoDia" class="text-sm"> - Pagamento: {{ assinatura.pagoDia | moment("DD/MM/YYYY") }}</span> </h3>
          <h3 v-if="assinatura.payment_method" class="text-gray-800 font-r font-medium text-xl mb-2">Método: {{ assinatura.payment_method === 'boleto' ? 'Boleto' : 'Cartão de Crédito' }} </h3>
          <h3 v-if="assinatura.pago" class="text-gray-800 font-r font-medium text-xl mb-2">Validade Plano: {{ assinatura.validade | moment("DD/MM/YYYY") }} </h3>
          <h3 class="text-gray-800 font-r font-medium text-xl mb-2">Status: {{ getStatus(assinatura.status) }} </h3>
          <h3 v-if="!assinatura.pago && assinatura.boleto_url" class="text-gray-800 font-r font-medium text-xl mb-2">Boleto: <a :href="assinatura.boleto_url" target="_blank" class="text-blue-500 underline">{{ assinatura.boleto_url }}</a></h3>
          <h3 v-if="!assinatura.pago && assinatura.boleto_expiration_date" class="text-gray-800 font-r font-medium text-xl mb-2">Validade boleto: {{ assinatura.boleto_expiration_date | moment("DD/MM/YYYY") }} </h3>
        </div>
         <h3 v-else class="text-gray-800 font-r font-medium text-xl mb-2">Fluxo Manual de Assinatura ativo </h3>
        <button v-if="!assinatura.pago" @click="fluxoAutomatico" class="text-white hover:bg-blue-500 bg-blue-700 rounded py-2 px-4">
          {{ user.fluxoAssinaturaManual ? 'Desativar Fluxo Manual' : 'Ativar Fluxo Manual' }}
        </button>
      </div>
    </modal>
    <modal name="linkLogin" :adaptive="true" :height="'auto'">
      <div class="px-5 py-5">
        <h2 class="text-xl mb-2 font-bold">LINK Login</h2>
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-8 sm:col-span-10">
            <input type="text" id="linklogin" disabled :value="link" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-4 sm:col-span-2">
            <button @click="copyClipboard" class="text-white hover:bg-blue-500 bg-blue-700 rounded py-2 px-4 w-full">
              Copiar
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

export default {
  data() {
    return {
      busca: '',
      link: '',
      route: 'users',
      list: [],
      emailDeletar: "",
      user: null,
      desativados: false,
      assinatura: null,
      page: 1,
      perPage: 100,
      total: 0,
      query: { page: 1, limit: 100, skip: 0  },
    }
  },
  methods: {

    formatData(data) {
      return `${moment(data).format("DD/MM/YYYY HH:mm:ss")} ${moment(data).fromNow()}`;
    },

    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { busca: this.busca, desativados: this.desativados, skip: this.query.skip, limit: this.query.limit  });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },
    
    async remove(id) {
      if (confirm("Tem certeza que deseja excluir esse usuário?")) {
        await this.$http.delete(`/v1/${this.route}/${id}`);
        this.$vToastify.success("Removido com sucesso!");
        this.start();
      }
    },

    async alterarLimitacao(usuario){
      if (confirm("Tem certeza que deseja alterar a limitação de projetos")) {
        const qtdEmpresa = usuario.qtdEmpresa === "Limitado a 1" ? "Ilimitado" : "Limitado a 1";
        await this.$http.put(`/v1/${this.route}/`,{ _id: usuario._id, qtdEmpresa });
        this.$vToastify.success("Salvo com sucesso!");
        this.start();
      }
    },

    async deletarEmail(){
      if (confirm("Tem certeza que deseja excluir este email da plataforma?")) {
        await this.$http.post(`/v1/${this.route}/deleteEmail`,{ email: this.emailDeletar });
        this.emailDeletar = "";
        this.$vToastify.success("Removido com sucesso!");
        this.start();
        this.$modal.hide('deletarEmail');
      }
    },

    openModalPlano(user) {
      this.assinatura = user.assinatura;
      this.user = user;
      this.$modal.show('plano');
    },

    async fluxoAutomatico () {
      const req = await this.$http.put(`/v1/${this.route}/`, { _id: this.user._id, fluxoAssinaturaManual: !this.user.fluxoAssinaturaManual }); 
      if (req.data.success) {
          this.$vToastify.success("Salvo com sucesso!");
          this.$modal.hide('plano');
          this.start();
      } else {
        this.$vToastify.error(req.data.err);
      }
    },

    async reativar(id){
      const req = await this.$http.put(`/v1/${this.route}/`, { _id: id, active: true }); 
      if (req.data.success) {
          this.$vToastify.success("Reativado com sucesso!");
          this.start();
      } else {
        this.$vToastify.error(req.data.err);
      }
    },

    async gerarLogin(id) {
      const req = await this.$http.get(`/v1/${this.route}/link-login/${id}`);
      if(!req.data.link) return this.$vToastify.error("Erro ao gerar login!");
      this.link = req.data.link;
      this.$modal.show('linkLogin');
    },

    copyClipboard () {
        navigator.clipboard.writeText(this.link);
        this.$vToastify.success("Copiado com sucesso!");
    },

    updateFiltro(){
      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { page: this.query.page, limit: this.query.limit, skip: this.query.skip, desativados: this.desativados, busca: this.busca };
      this.$router.push({ path: `/${this.route}`, query: this.query });
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;
      this.query.busca = this.busca;
      this.query.desativados = this.desativados;

      this.$router.push({ path: `/${this.route}`, query: this.query });
    },

    getStatus(status){
      switch(status){
          case 1: return 'Plano cancelado';
          case 2: return 'Plano pago';
          case 3: return 'Aguardando pagamento';
          case 4: return 'Falha pagamento';
          case 5: return 'Processando Pagamento';
          case 6: return 'Processando Assinatura';
          default: return '';
      }
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.busca) this.busca = this.$route.query.busca;
    if(this.$route.query.desativados) this.desativados = this.$route.query.desativados === 'true' ? true : false;
    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async beforeMount() {
    if(this.$route.query.busca) this.busca = this.$route.query.busca;
    if(this.$route.query.desativados) this.desativados = this.$route.query.desativados === 'true' ? true : false;
    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();

    this.$watch('desativados', async (desativada) => {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { busca: this.busca, desativados: desativada, skip: this.query.skip, limit: this.query.limit });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    });
  },
}
</script>